.container {
  min-height: 100vh;
  padding: 0 0.5rem;
}

.main {  
  padding: 2.5rem;
  margin-top: 7.5rem;
  width: 100%;
}

.background {
  background-image: url("/Nandos.jpg");
}

.footer {
  color: #ffffff;
  width: 100%;
  height: 100px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer img {
  margin-left: 0.5rem;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.required {
  color: #ff0000
}

.indextext {
  flex: 1;
  justify-content: top;
  align-items: center;
}

.a {
  color: inherit;
  text-decoration: none;
}

.title a {
  color: #ffffff;
  background: #000000;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin-bottom: 5rem;
  line-height: 1.15;
  font-size: 4rem;
  position: absolute;
  top: 0%;
}

.title,
.description {
  text-align: center;
}

.description {
  padding: 0rem;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #ffcccb;
  border-radius: 3px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
  -webkit-animation: fade-in 10s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 10s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  color: #cf2c2cdb;
  margin: 1rem;
  flex-basis: 45%;
  padding: 2rem;
  text-align: center;
  color: inherit;
  text-decoration: none;
  border-radius: 50px;
  transition: color 0.15s ease, border-color 0.15s ease;
  width: 300px;
  height: 170px;
  flex: 1;
}

.card:hover,
.card:focus,
.card:active {
  color: #ffffff;
  border-color: #ffffff;
}

.card h3 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  color: #000000;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #000000;
}

.logo {
  height: 1em;
}

.appbar {
  color: #000000
}

.appbar text {
  color: #000000;
  font-size: 1.25rem;

}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}
@keyframes bounce-in-top {
0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
opacity: 0;
}
38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
opacity: 1;
}
55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
}
72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
}
81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
}
90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
}
95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
}
100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
}
}
  @-webkit-keyframes fade-in {
  0% {
  opacity: 0;
    }
  100% {
  opacity: 1;
    }
  }
@keyframes fade-in {
  0% {
  opacity: 0;
    }
  100% {
  opacity: 1;
  }
}

.loadingContainer {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
